import * as React from "react";

export enum Theme {
  DARK = "DARK",
  LIGHT = "LIGHT",
}

const themeContext = {
  theme: Theme.DARK,
  toggle: null,
};

export const ThemeContext = React.createContext(themeContext);

const THEME_KEY = "theme";

const ThemeProvider: React.FC = ({ children }) => {
  const [theme, setTheme] = React.useState(themeContext.theme);

  React.useEffect(() => {
    // Test if dark theme is requested by the OS
    const isDarkRequested =
      window.matchMedia("(prefers-color-scheme: dark").matches === true;
    if (isDarkRequested) {
      setTheme(Theme.DARK);
    } else {
      try {
        const preference = localStorage.getItem(THEME_KEY);
        const parsedTheme = JSON.parse(preference);
        setTheme(parsedTheme as Theme);
      } catch {}
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem(THEME_KEY, JSON.stringify(theme));
  }, [theme]);

  const toggleTheme = () =>
    theme === Theme.DARK ? setTheme(Theme.LIGHT) : setTheme(Theme.DARK);

  return (
    <ThemeContext.Provider value={{ theme, toggle: toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
